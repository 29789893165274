// IMPORTANT !!!!!!!!
// copy from:
// @import "components/variables.custom";
// @import "components/variables";
// @import "bootstrap/scss/variables";
// @import "vendors/plugins/variables";
// @import "layout/base/variables";
// @import "layout/variables.custom";
//

// IMPORTANT IMPORTANT !!!!!!!!
// @impor in _init.scss
//

// Bootstrap color system
$white: 											#ffffff;


$primary:       #d71921; //IBSEDU color
$primary-active: scale-color(#d71921,$lightness: -10%);

$primary-light:    			#f1faff;
$primary-light-dark:    #212e48;
$primary-inverse:  			$white;

// Success
$success:       				#50cd89;
$success-active:    		#47be7d;
$success-light:    			#e8fff3;
$success-light-dark:    #1c3238;
$success-inverse:  			$white;

// Info
$info:       						#7239ea;
$info-active:    				#5014d0;
$info-light:    				#f8f5ff;
$info-light-dark:    		#2f264f;
$info-inverse:  				$white;

// Danger
$danger:       					#f1416c;
$danger-active:    			#d9214e;
$danger-light:    			#fff5f8;
$danger-light-dark:    	#3a2434;
$danger-inverse:  			$white;

// Warning
$warning:       				#ffc700;
$warning-active:    		#f1bc00;
$warning-light:    			#fff8dd;
$warning-light-dark:    #392f28;
$warning-inverse:  			$white;


// Forms
$form-label-color:      #A1A5B7;// $gray-500;
$form-label-color-dark: #565674;// $gray-500-dark;

$form-check-label-color:     #A1A5B7;
$form-check-label-color-dark:#565674;




$spacer: 1rem;
// Keenthemes custom gutter sizes
$gutters: (
  0: 0rem,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: ($spacer * .75),
  4: ($spacer * 1),
  5: ($spacer * 1.25),
  6: ($spacer * 1.5),
  7: ($spacer * 1.75),
  8: ($spacer * 2),
  9: ($spacer * 2.25),
  10: ($spacer * 2.5),
  11: ($spacer * 2.75),
  12: ($spacer * 3),
  13: ($spacer * 3.25),
  14: ($spacer * 3.5),
  15: ($spacer * 3.75),
  16: ($spacer * 4),
  17: ($spacer * 4.25),
  18: ($spacer * 4.5),
  19: ($spacer * 4.75),
  20: ($spacer * 5),
  21: ($spacer * 5.25),
  22: ($spacer * 5.5),
  23: ($spacer * 5.75),
  24: ($spacer * 6),
  25: ($spacer * 6.25)
);

$spacers: (
	0: 0,
	1: ($spacer * .25), // 3.5px
	2: ($spacer * .5), // 7px;
	3: ($spacer * .75), // 10.5px
	4: ($spacer * 1), // 14px
	5: ($spacer * 1.25), // 17.5px
	6: ($spacer * 1.5),  // 21px
	7: ($spacer * 1.75), // 24.5px
	8: ($spacer * 2), // 28px
	9: ($spacer * 2.25), // 31.5px
	10: ($spacer * 2.5), // 35px
	11: ($spacer * 2.75), // 38.5px
	12: ($spacer * 3),   	// 42px
	13: ($spacer * 3.25),	// 45.5px
	14: ($spacer * 3.5), 	// 49px
	15: ($spacer * 3.75), 	// 52.5px
	16: ($spacer * 4), 		// 55px
	17: ($spacer * 4.25), 	// 58.5px
	18: ($spacer * 4.5), 	// 62px
	19: ($spacer * 4.75), 	// 65.5px
	20: ($spacer * 5), 		// 69px
  21: ($spacer * 5.25),
  22: ($spacer * 5.5),
  23: ($spacer * 5.75),
  24: ($spacer * 6),
  25: ($spacer * 6.25)
);

// Keenthemes hight and width sizes
$custom-sizes: (
	unset: unset,
  5: 5%,
  10: 10%,
  15: 15%,
	20: 20%,
	25: 25%,
	30: 30%,
	33: 33.3333333333%,
	35: 35%,
	40: 40%,
	45: 45%,
	50: 50%,
	55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
	75: 75%,
	80: 80%,
	85: 85%,
	90: 90%,
	95: 95%,
	100: 100%,
	auto: auto,
	1px: 1px,
	2px: 2px,
	3px: 3px,
	4px: 4px,
	5px: 5px,
	6px: 6px,
	7px: 7px,
	8px: 8px,
	9px: 9px,
	10px: 10px,
	15px: 15px,
	20px: 20px,
	25px: 25px,
	30px: 30px,
	35px: 35px,
	40px: 40px,
	45px: 45px,
	50px: 50px,
	55px: 55px,
	60px: 60px,
	65px: 65px,
	70px: 70px,
	75px: 75px,
	80px: 80px,
	85px: 85px,
	90px: 90px,
	95px: 95px,
	100px: 100px,
	125px: 125px,
	150px: 150px,
	175px: 175px,
	200px: 200px,
	225px: 225px,
	250px: 250px,
	275px: 275px,
	300px: 300px,
	325px: 325px,
	350px: 350px,
	375px: 375px,
	400px: 400px,
	425px: 425px,
	450px: 450px,
	475px: 475px,
	500px: 500px,
	550px: 550px,
	600px: 600px,
	650px: 650px,
	700px: 700px,
	750px: 750px,
	800px: 800px,
	850px: 850px,
	900px: 900px,
	950px: 950px,
	1000px: 1000px
) ;
